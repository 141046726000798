<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div style="background: #DBE7F2" class="p-2">
            <TitleButton
                btnTitle="Add New"
                :showBtn="false"
                :showSettingBtn="false"
                :showAddNew="false"
                title="Landed Costing List"
            />
            <DateQuerySetter @onClickGo="getLandedCostingList">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label for="colFormLabel" class="col-form-label">Status</label>
                    <vField v-model="status" class="d-none" name="status"/>
                    <v-select
                        placeholder="Select Status"
                        v-model="status"
                        :options="allStatus"
                        label="name"
                        :reduce="name => name.id"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <ListTable :list="landedCostingList"/>
        <div class="mb-2"></div>
        <div class="px-2 position-absolute bottom-0">
            <Pagination
                :offset="offset"
                @onPageChange="onPageChange"
                ref="pagination"
            />
        </div>
    </div>
    <GlobalLoader/>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/scm/landed-costing/LandedCostingListTable.vue'
import Pagination from '@/components/atom/Pagination'
import {
    inject,
    ref,
    onMounted,
    computed,
    watch
} from 'vue';
import {
    useRoute,
    useRouter
} from "vue-router";
import {useStore} from "vuex";
import handleLandedCosting from "@/services/modules/scm/landed-costing";

const router  = useRouter()
const route   = useRoute()
const loading = ref(false);
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const {fetchLandedCosting} = handleLandedCosting();

let landedCostingList = ref([]);
let companyId = computed(() => route.params.companyId);
const offset = ref(20);
const page = computed(() => route.query.page);
const loader = ref(false);
let allStatus = ref([
    {
        id  : '',
        name: 'All'
    },
    {
        id  : 'pending',
        name: 'Pending'
    },
    {
        id  : 'approved',
        name: 'Approved'
    }
])
let status = ref('')

function getQuery() {
    let query = '?company_id=' + companyId.value
    query += '&offset=' + offset.value
    query += '&start_date=' + route.query.start
    query += '&end_date=' + route.query.end
    query += '&status=' + (status.value == null ? '' : status.value)
    if (page.value) query += '&page=' + page.value
    return query
}

async function getLandedCostingList() {
    try {
        const res = await fetchLandedCosting(getQuery());
        if (!res.status) {
          landedCostingList.value = [];
          return resetPagination()
        }
        landedCostingList.value = res.data.data;
        await setPagination(res.data)
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

function onPageChange(page) {
    let routeQuery  = Object.assign({}, route.query)
    routeQuery.page = page
    router.push({path: route.path, query: routeQuery})
    setTimeout(() => {
        getLandedCostingList();
    }, 100)
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};
const resetPagination = () => {
  store.commit('resetPagination')
};
const setPagination = (data) => {
  setPaginationData({
    records: data.total,
    perPage: data.per_page,
    currentPage: data.current_page,
  })
}
onMounted(async () => {
    await getLandedCostingList();
})
</script>
